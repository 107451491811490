export const config = () => {
  return {
    develop: {
      frontend: "https://d2wzilqw01b1ri.cloudfront.net/",
      secret: "026d12a2-54ae-47c9-897d-73e07c128396",
      accountId: "mosaic-testing",
    },
    beta: {
      frontend: "https://meet-demo.combobulate.com/",
      secret: "026d12a2-54ae-47c9-897d-73e07c128396",
      accountId: "mosaic-testing",
    },
    local: {
      frontend: "http://localhost:3000/",
      secret: "026d12a2-54ae-47c9-897d-73e07c128396",
      accountId: "mosaic-testing",
    },
  };
};
