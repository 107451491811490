import "./App.css";
import backend from "./backend.json";
import MeetTile from "./MeetTile";
import { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Login from "./login";

const environments = ["local", "develop", "beta"];

function App() {
  const [environment, setEnvironment] = useState("beta");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [loggedIn, setloggedIn] = useState(false);
  const isLocal = process.env.NODE_ENV === "development";

  useEffect(() => {
    const fName = localStorage.getItem("firstName");
    const lName = localStorage.getItem("lastName");
    console.log(fName, lName);
    if (
      ![null, undefined, ""].includes(fName) &&
      ![null, undefined, ""].includes(lName)
    ) {
      setFirstName(fName);
      setLastName(lName);
      setloggedIn(true);
    }
  }, []);

  const user = {
    firstName,
    lastName,
  };

  return (
    <div className="App">
      <header className="App-header">
        <h2>Combobulate Meet Beta</h2>
        {loggedIn && (
          <div>
            <span style={{ marginRight: "1rem" }}>
              {firstName} {lastName}
            </span>
            <Button
              color="primary"
              onClick={() => {
                setLastName("");
                setFirstName("");
                localStorage.setItem("firstName", "");
                localStorage.setItem("lastName", "");
                setloggedIn(false);
              }}
            >
              (Logout)
            </Button>
          </div>
        )}
      </header>
      <main className="App-main">
        {isLocal && (
          <div className="App-options">
            <label htmlFor="environment">Choose an environment: </label>
            <select
              onChange={(e) => setEnvironment(e.target.value)}
              name="environment"
              id="environment"
              value={environment}
            >
              {environments.map((env) => (
                <option key={env} value={env}>
                  {env}
                </option>
              ))}
            </select>
          </div>
        )}
        {loggedIn ? (
          <div className="App-tile-container">
            {Object.keys(backend).map((meeting) => (
              <MeetTile
                key={backend[meeting].eventId}
                meeting={backend[meeting]}
                user={user}
                env={environment}
              />
            ))}
          </div>
        ) : (
          <Login
            setLastName={setLastName}
            setFirstName={setFirstName}
            firstName={firstName}
            lastName={lastName}
            setloggedIn={setloggedIn}
          />
        )}
      </main>
      <footer className="App-footer">
        <p>Mosaic Learning</p>
      </footer>
    </div>
  );
}

export default App;
