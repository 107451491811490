import "./MeetTile.css";
import { createToken } from "./backend";
import { config } from "./config";
import { useState } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";

function MeetTile({ meeting, user, env }) {
  const configObj = config();
  const [anchorEl, setAnchorEl] = useState(null);
  const [role, setRole] = useState("attendee");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const sendToMeet = (type, isAdmin) => {
    const token = createToken(
      meeting.eventId,
      meeting.title,
      type,
      isAdmin ? "admin" : role,
      env,
      user.firstName,
      user.lastName
    );
    let link = `${configObj[env].frontend}sso?token=${token}&accountid=${configObj[env].accountId}`;
    window.open(link, "_blank");
  };

  const handleChange = (event) => {
    setRole(event.target.checked ? "presenter" : "attendee");
  };

  return (
    <section className="meet-tile">
      <div className="image-container">
        <img
          className="meet-img"
          src={meeting.image}
          alt={`${meeting.title}`}
        ></img>
        <div className="img-btn">
          <Button size="small" onClick={handleClick}>
            ...
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                sendToMeet(meeting.settingsType, true);
                setAnchorEl(null);
              }}
            >
              Settings
            </MenuItem>
          </Menu>
        </div>
      </div>
      <h2>{meeting.title}</h2>

      <Button
        color="primary"
        variant="contained"
        className="tile-button"
        disabled={
          [null, "", undefined].includes(user.firstName) ||
          [null, "", undefined].includes(user.lastName)
        }
        onClick={() => sendToMeet(meeting.type, false)}
      >
        Join Now
      </Button>

      <FormGroup row style={{ justifyContent: "center" }}>
        <FormControlLabel
          control={
            <Switch
              checked={role === "presenter"}
              onChange={handleChange}
              name="role selection"
            />
          }
          label="Presenter"
        />
      </FormGroup>
    </section>
  );
}

export default MeetTile;
