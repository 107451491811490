import jwt from "jsonwebtoken";
import { config } from "./config";
import { v4 as uuid } from "uuid";

export const createToken = (
  eventId,
  title,
  type,
  role,
  environment,
  firstName,
  lastName
) => {
  const configObj = config();
  const data = {
    accountId: "mosaic-testing",
    firstName: firstName,
    lastName: lastName,
    role,
    email: "anonymous@fakecms.com",
    eventId,
    title,
    type,
    userId: uuid(),
  };

  const options = {
    expiresIn: "24h",
  };

  return jwt.sign(data, configObj[environment].secret, options);
};
