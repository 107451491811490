import "./App.css";
import Button from "@material-ui/core/Button";

function Login({
  setLastName,
  setFirstName,
  firstName,
  lastName,
  setloggedIn,
}) {
  const handleLogin = () => {
    //check names
    localStorage.setItem("firstName", firstName);
    localStorage.setItem("lastName", lastName);
    setloggedIn(true);
  };

  return (
    <div className="user-select">
      <label htmlFor="role">First Name:</label>
      <input type="text" onChange={(e) => setFirstName(e.target.value)} />
      {firstName === "" && (
        <p style={{ color: "red" }}>Please Enter First Name</p>
      )}
      <label htmlFor="role">Last Name:</label>
      <input type="text" onChange={(e) => setLastName(e.target.value)} />
      {lastName === "" && (
        <p style={{ color: "red" }}>Please Enter Last Name</p>
      )}
      <Button variant="contained" color="primary" onClick={handleLogin}>
        login
      </Button>
    </div>
  );
}

export default Login;
